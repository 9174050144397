var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"pa-0"},[_c('v-btn',{staticClass:"change-view-button",attrs:{"color":"white","elevation":"0"},on:{"click":function($event){return _vm.$router.push({name: 'TablaGeneralSensores', params: {codigo: _vm.codigo}})}}},[_vm._v(" Cambiar a vista tabla ")]),_c('v-card',{staticClass:"table-container mt-5",attrs:{"elevation":"0"}},[_c('v-card-title',{staticClass:"px-8 py-8"},[_c('p',{staticClass:"table-title"},[_vm._v(" Todos los sensores ")]),_c('v-spacer'),_c('v-text-field',{staticClass:"sensor-searcher",attrs:{"append-icon":"mdi-magnify","single-line":"","hide-details":"","outlined":"","dense":"","color":"#C2C2C2","placeholder":"Buscar sensores","width":"100px"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-data-table',{staticClass:"sensores-table",attrs:{"headers":_vm.headers,"items":_vm.arraySensoresFormateados,"single-expand":true,"expanded":_vm.expanded,"item-key":"codigo","hide-default-footer":"","items-per-page":-1,"search":_vm.search,"custom-filter":_vm.filterSensors,"loading":_vm.loading},on:{"update:expanded":function($event){_vm.expanded=$event}},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
var isExpanded = ref.isExpanded;
var expand = ref.expand;
return [_c('tr',{on:{"click":function($event){expand(!isExpanded); _vm.fetchRegistrosGrafica(!isExpanded, item)}}},[_c('td',[_c('div',{ref:item.ref,staticClass:"d-flex align-center"},[_c('div',[_c('v-avatar',{attrs:{"size":"44px"}},[_c('v-skeleton-loader',{staticClass:"profile-image",attrs:{"alt":"Avatar","type":"avatar"}})],1)],1),_c('div',{staticClass:"pl-6"},[_c('h5',{staticClass:"sensores-item__title"},[_vm._v(_vm._s(item.nombre))]),_c('h5',{staticClass:"sensores-item__subtitle"},[_vm._v(_vm._s(_vm._f("moment")(item.updated,'from', 'now')))])])])]),_c('td',[_c('p',{staticClass:"sensores-item__title"},[_vm._v(_vm._s(item.ultimo_registro ? item.ultimo_registro.medida : ''))])]),_c('td',[_c('p',{staticClass:"sensores-item__title"},[_vm._v(_vm._s(item.unidad_medida ? item.unidad_medida.simbolo : ''))])]),_c('td',[_c('v-icon',{attrs:{"color":_vm.getColor(item.estado),"dark":""}},[_vm._v("mdi-map-marker")])],1),_c('td',{on:{"click":function($event){$event.stopPropagation();}}},[_c('v-menu',{staticClass:"graficas-menu",attrs:{"bottom":"","left":"","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"my-auto icon-color",on:{"click":function($event){$event.stopPropagation();}}},'v-icon',attrs,false),on),[_vm._v("mdi-dots-vertical")])]}}],null,true)},[_c('v-card',{staticClass:"options-card",attrs:{"elevation":"0"}},[_c('div',{staticClass:"mb-0 options-card__item d-flex justify-center align-center ",on:{"click":function($event){expand(true); _vm.$set(item, 'tipoGrafica', 'lineal')}}},[_vm._v("Gráfica lineal ")]),_c('div',{staticClass:"mb-0 options-card__item d-flex justify-center align-center",on:{"click":function($event){expand(true); _vm.$set(item, 'tipoGrafica', 'radial')}}},[_vm._v("Velocimetro ")])])],1)],1)])]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('v-expand-transition',[_c('td',{attrs:{"colspan":headers.length}},[_c('div',{staticStyle:{"min-height":"380px","max-height":"380px","overflow":"hidden"}},[_c('v-scroll-x-transition',[_c('div',{directives:[{name:"show",rawName:"v-show",value:(item.tipoGrafica === 'radial'),expression:"item.tipoGrafica === 'radial'"}],staticStyle:{"height":"100%"}},[_c('div',{staticClass:"d-flex justify-center align-center mx-auto",staticStyle:{"width":"100%"}},[_c('apexchart',{ref:("graficaRadial" + (item.codigo)),staticClass:"mb-12",staticStyle:{"width":"55%","max-height":"380px"},attrs:{"options":item.graficaRadial.options,"series":item.graficaRadial.series}})],1)])]),_c('v-scroll-x-transition',[_c('div',{directives:[{name:"show",rawName:"v-show",value:(item.tipoGrafica !== 'radial'),expression:"item.tipoGrafica !== 'radial'"}],staticStyle:{"height":"100%","max-height":"380px"}},[_c('div',{staticClass:"d-flex justify-center align-center"},[_c('div',{staticStyle:{"width":"50%","height":"380px","max-height":"380px","max-width":"580px"}},[_c('apexchart',{ref:("graficaArea" + (item.codigo)),staticClass:"my-5",attrs:{"type":"area","options":item.graficaArea.options,"series":item.graficaArea.series}})],1),_c('div',{staticStyle:{"width":"45%"}},[_c('div',{staticClass:"grafica-input my-2"},[_c('p',{staticClass:"grafica-input__label mx-4"},[_vm._v("Desde: ")]),_c('v-menu',{attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({staticClass:"grafica-input__input",attrs:{"value":_vm.formatDate(item.graficaArea.desde),"append-icon":"mdi-calendar","readonly":"","outlined":"","dense":"","hide-details":"","locale":"es","loading":item.graficaArea.loading}},'v-text-field',attrs,false),on))]}}],null,true),model:{value:(_vm.showDateMenu),callback:function ($$v) {_vm.showDateMenu=$$v},expression:"showDateMenu"}},[_c('v-date-picker',{attrs:{"no-title":""},on:{"input":function($event){_vm.showDateMenu = false; _vm.actualizarGraficaArea(item)}},model:{value:(item.graficaArea.desde),callback:function ($$v) {_vm.$set(item.graficaArea, "desde", $$v)},expression:"item.graficaArea.desde"}})],1)],1),_c('div',{staticClass:"grafica-input my-2"},[_c('p',{staticClass:"grafica-input__label mx-4"},[_vm._v("Hasta: ")]),_c('v-menu',{attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({staticClass:"grafica-input__input",attrs:{"value":_vm.formatDate(item.graficaArea.hasta),"append-icon":"mdi-calendar","readonly":"","outlined":"","dense":"","hide-details":"","locale":"es","loading":item.graficaArea.loading}},'v-text-field',attrs,false),on))]}}],null,true),model:{value:(_vm.showDateMenuHasta),callback:function ($$v) {_vm.showDateMenuHasta=$$v},expression:"showDateMenuHasta"}},[_c('v-date-picker',{attrs:{"no-title":""},on:{"input":function($event){_vm.showDateMenuHasta = false; _vm.actualizarGraficaArea(item)}},model:{value:(item.graficaArea.hasta),callback:function ($$v) {_vm.$set(item.graficaArea, "hasta", $$v)},expression:"item.graficaArea.hasta"}})],1)],1),_c('div',{staticClass:"grafica-input my-2"},[_c('p',{staticClass:"grafica-input__label mx-4"},[_vm._v("Sensores: ")]),_c('v-select',{staticClass:"grafica-input__input",attrs:{"dense":"","hide-details":"","outlined":"","items":_vm.sensores,"item-text":"nombre","item-value":"codigo","no-data-text":"No hay mas sensores disponibles","multiple":"","chips":"","deletable-chips":"","hide-selected":""},on:{"input":function($event){return _vm.actualizarGraficaArea(item)}},model:{value:(item.sensoresGrafica),callback:function ($$v) {_vm.$set(item, "sensoresGrafica", $$v)},expression:"item.sensoresGrafica"}})],1),_c('div',{staticClass:"grafica-input my-2 mt-4"},[_c('p',{staticClass:"grafica-input__label mx-4"}),_c('v-btn',{staticClass:"base-button flex-grow-1",attrs:{"outlined":"","elevation":"0"},on:{"click":function($event){_vm.sensorSelectedToExpand=item}}},[_vm._v(" Expandir ")])],1)])])])])],1)])])]}}])})],1),_c('chart-lineal-registros-sensor-modal',{attrs:{"currentSensor":_vm.sensorSelectedToExpand,"sensores":_vm.sensores},on:{"updateChart":function($event){return _vm.actualizarGraficaArea($event)}},model:{value:(_vm.showOnExpand),callback:function ($$v) {_vm.showOnExpand=$$v},expression:"showOnExpand"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }