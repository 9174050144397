<template>
  <v-container class="pa-0">
    <v-btn class="change-view-button" color="white" elevation="0"
           @click="$router.push({name: 'TablaGeneralSensores', params: {codigo: codigo}})">
      Cambiar a vista tabla
    </v-btn>
    <v-card elevation="0" class="table-container mt-5">
      <v-card-title class="px-8 py-8">
        <p class="table-title">
          Todos los sensores
        </p>
        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          single-line
          hide-details
          class="sensor-searcher"
          outlined
          dense
          color="#C2C2C2"
          placeholder="Buscar sensores"
          width="100px"
        ></v-text-field>
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="arraySensoresFormateados"
        class="sensores-table"
        :single-expand="true"
        :expanded.sync="expanded"
        item-key="codigo"
        hide-default-footer
        :items-per-page="-1"
        :search="search"
        :custom-filter="filterSensors"
        :loading="loading"
      >
        <template v-slot:item="{ item, isExpanded, expand }">
          <tr @click="expand(!isExpanded); fetchRegistrosGrafica(!isExpanded, item)">
            <td>
              <div :ref="item.ref" class="d-flex align-center">
                <div>
                  <v-avatar
                    size="44px"
                  >
                    <v-skeleton-loader
                      alt="Avatar"
                      type="avatar"
                      class="profile-image"
                    ></v-skeleton-loader>
                  </v-avatar>
                </div>
                <div class="pl-6">
                  <h5 class="sensores-item__title">{{ item.nombre }}</h5>
                  <h5 class="sensores-item__subtitle">{{ item.updated | moment('from', 'now') }}</h5>
                </div>
              </div>
            </td>
            <td>
              <p class="sensores-item__title">{{ item.ultimo_registro ? item.ultimo_registro.medida : '' }}</p>
            </td>
            <td>
              <p class="sensores-item__title">{{ item.unidad_medida ? item.unidad_medida.simbolo : '' }}</p>
            </td>
            <td>
              <v-icon :color="getColor(item.estado)" dark>mdi-map-marker</v-icon>
            </td>

            <td @click.stop>
              <v-menu
                bottom
                left
                offset-y
                class="graficas-menu"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-icon v-on="on" v-bind="attrs" @click.stop class="my-auto icon-color">mdi-dots-vertical</v-icon>
                </template>

                <v-card class="options-card" elevation="0">
                  <div @click="expand(true); $set(item, 'tipoGrafica', 'lineal')"
                       class="mb-0 options-card__item d-flex justify-center align-center ">Gráfica lineal
                  </div>
                  <div @click="expand(true); $set(item, 'tipoGrafica', 'radial')"
                       class="mb-0 options-card__item d-flex justify-center align-center">Velocimetro
                  </div>
                </v-card>
              </v-menu>
            </td>
          </tr>
        </template>

        <template v-slot:expanded-item="{ headers, item }">
          <v-expand-transition>
            <td :colspan="headers.length">
              <div style="min-height: 380px; max-height: 380px; overflow: hidden;">
                <v-scroll-x-transition>
                  <div v-show="item.tipoGrafica === 'radial'" style="height: 100%">
                    <div class="d-flex justify-center align-center mx-auto" style="width: 100%">
                      <apexchart
                        :ref="`graficaRadial${item.codigo}`"
                        :options="item.graficaRadial.options"
                        :series="item.graficaRadial.series"
                        class="mb-12"
                        style="width: 55%; max-height: 380px;"
                      ></apexchart>
                    </div>
                  </div>
                </v-scroll-x-transition>
                <v-scroll-x-transition>
                  <div v-show="item.tipoGrafica !== 'radial'" style="height: 100%;max-height: 380px;">
                    <div class="d-flex justify-center align-center">
                      <div style="width: 50%; height: 380px; max-height: 380px; max-width: 580px;">
                        <apexchart
                          :ref="`graficaArea${item.codigo}`"
                          type="area"
                          :options="item.graficaArea.options"
                          :series="item.graficaArea.series"
                          class="my-5"
                        >
                        </apexchart>
                      </div>
                      <div style="width: 45%">
                        <div class="grafica-input my-2">
                          <p class="grafica-input__label mx-4">Desde: </p>
                          <v-menu
                            v-model="showDateMenu"
                            :close-on-content-click="false"
                            transition="scale-transition"
                            offset-y
                            max-width="290px"
                            min-width="auto"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field
                                :value="formatDate(item.graficaArea.desde)"
                                append-icon="mdi-calendar"
                                readonly
                                class="grafica-input__input"
                                outlined
                                dense
                                hide-details
                                locale="es"
                                v-bind="attrs"
                                v-on="on"
                                :loading="item.graficaArea.loading"
                              ></v-text-field>
                            </template>
                            <v-date-picker
                              v-model="item.graficaArea.desde"
                              no-title
                              @input="showDateMenu = false; actualizarGraficaArea(item)"
                            ></v-date-picker>
                          </v-menu>

                        </div>
                        <div class="grafica-input my-2">
                          <p class="grafica-input__label mx-4">Hasta: </p>
                          <v-menu
                            v-model="showDateMenuHasta"
                            :close-on-content-click="false"
                            transition="scale-transition"
                            offset-y
                            max-width="290px"
                            min-width="auto"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field
                                :value="formatDate(item.graficaArea.hasta)"
                                append-icon="mdi-calendar"
                                readonly
                                class="grafica-input__input"
                                outlined
                                dense
                                hide-details
                                locale="es"
                                v-bind="attrs"
                                v-on="on"
                                :loading="item.graficaArea.loading"
                              ></v-text-field>
                            </template>
                            <v-date-picker
                              v-model="item.graficaArea.hasta"
                              no-title
                              @input="showDateMenuHasta = false; actualizarGraficaArea(item)"
                            ></v-date-picker>
                          </v-menu>

                        </div>
                        <div class="grafica-input my-2">
                          <p class="grafica-input__label mx-4">Sensores: </p>
                          <v-select
                            dense hide-details
                            class="grafica-input__input"
                            outlined
                            v-model="item.sensoresGrafica"
                            :items="sensores"
                            item-text="nombre"
                            item-value="codigo"
                            no-data-text="No hay mas sensores disponibles"
                            multiple
                            chips
                            deletable-chips
                            hide-selected
                            @input="actualizarGraficaArea(item)"
                          >
                          </v-select>
                        </div>
                        <div class="grafica-input my-2 mt-4">
                          <p class="grafica-input__label mx-4"></p>
                          <v-btn
                            class="base-button flex-grow-1"
                            outlined
                            elevation="0"
                            @click="sensorSelectedToExpand=item"
                          >
                            Expandir
                          </v-btn>
                        </div>
                      </div>
                    </div>
                  </div>
                </v-scroll-x-transition>
              </div>
            </td>
          </v-expand-transition>
        </template>

      </v-data-table>
    </v-card>

    <chart-lineal-registros-sensor-modal
      v-model="showOnExpand"
      :currentSensor="sensorSelectedToExpand"
      :sensores="sensores"
      @updateChart="actualizarGraficaArea($event)"
    ></chart-lineal-registros-sensor-modal>
  </v-container>
</template>

<script>
import AppBar from '@/components/layout/AppBar'
import axios from '@/plugins/axios'
import {opcionesDefaultGraficaRadial, opcionesDefaultGraficaArea} from '@/utils/charts'
import moment from 'moment'
import Paho from 'paho-mqtt/paho-mqtt'
import ChartLinealRegistrosSensorModal from "@/components/modals/ChartLinealRegistrosSensorModal";

export default {
  name: 'Dispositivo',
  components: {
    AppBar,
    ChartLinealRegistrosSensorModal
  },
  props: ['codigo'],
  data() {
    return {
      expanded: [],
      title: 'Dispositivo',
      search: '',
      headers: [
        {
          text: 'Detalle del sensor',
          align: 'start',
          value: 'detalle',
        },
        {text: 'Último dato', value: 'numeroSensores'},
        {text: 'Unidades', value: 'unidad', align: 'center'},
        {text: 'Estado', value: 'estado', align: 'center'},
        {text: '', value: 'actions'},
      ],
      tiempos: [
        {
          value: 'hours',
          text: 'Hora(s)',
        },
        {
          value: 'days',
          text: 'Día(s)',
        },
        {
          value: 'months',
          text: 'Mes(es)',
        },
        {
          value: 'years',
          text: 'Año(s)',
        },
      ],
      sensores: [],
      arraySensoresFormateados: [],
      opcionesDefaultGraficaRadial,
      opcionesDefaultGraficaArea,
      timeout: null,
      empresa: null,
      showDateMenu: false,
      showDateMenuHasta: false,
      loading: false,
      sensorSelectedToExpand: null,
    }
  },
  watch: {
    async codigo() {
      await this.prepareInformation()
    },
  },
  computed: {
    showOnExpand: {
      get() {
        return !!this.sensorSelectedToExpand
      },
      set() {
        if (!!this.showOnExpand) {
          this.sensorSelectedToExpand = null
        }
      }
    }
  },
  methods: {
    async fetchSensores() {
      const sensoresResponse = await axios.get(`api/iot/dispositivos/${this.codigo}/sensores/`)
      this.sensores = sensoresResponse.data
    },
    async fetchEmpresa() {
      const empresaResponse = await axios.get(`api/empresas/`)
      this.empresa = empresaResponse.data
    },
    getColor(estado) {
      if (estado === 'MEDIO') return '#FEC400'
      else if (estado === 'NORMAL') return '#29CC97'
      else return '#FF3737'
    },
    fetchRegistros(codigoDispositivo, codigoSensor, timestampInicio, timestampFin) {
      const fechaInicio = moment(timestampInicio).format('YYYY-MM-DD hh:mm:ss')
      const fechaFin = moment(timestampFin).format('YYYY-MM-DD hh:mm:ss')
      return axios.get(`api/iot/registros_historicos/for_apex_resumido/?sensor__codigo=${codigoSensor}&sensor__dispositivo__codigo=${codigoDispositivo}&fecha_real__gte=${fechaInicio}&fecha_real__lte=${fechaFin}`)
    },
    actualizarGraficaArea(sensor) {
      clearTimeout(this.timeout)
      this.timeout = setTimeout(async () => {
        sensor.graficaArea.loading = true
        const desde = sensor.graficaArea.desde
        const hasta = sensor.graficaArea.hasta

        sensor.graficaArea.series = []
        await Promise.all(
          sensor.sensoresGrafica.map(async (codigoSensorSeleccionado) => {
            const sensorSeleccionado = this.sensores.find((sensor) => sensor.codigo === codigoSensorSeleccionado)
            const registrosResponse = await this.fetchRegistros(this.codigo, sensorSeleccionado.codigo, desde, hasta)
            const nuevaSerie = {
              name: sensorSeleccionado.nombre,
              data: registrosResponse.data,
            }
            sensor.graficaArea.series.push(nuevaSerie)
          })
        )
        this.$refs[`graficaArea${sensor.codigo}`].updateSeries(sensor.graficaArea.series)
        this.$root.$emit('updatedSensorSeries', sensor)
        sensor.graficaArea.loading = false
      }, 600)
    },
    formatearSensores(sensores) {
      return sensores.map((sensor) => {
        const currentSensor = {...sensor}
        currentSensor.sensoresGrafica = [sensor.codigo]

        // Obteniendo data para grafica radial
        currentSensor.graficaRadial = {}
        currentSensor.graficaRadial.options = {
          ...this.opcionesDefaultGraficaRadial,
          labels: [sensor.nombre],
          chart: {
            ...this.opcionesDefaultGraficaRadial.chart,
            umed: [sensor.umed]
          },
          subtitle: {
            ...this.opcionesDefaultGraficaRadial.subtitle,
            text: [sensor.descripcion]
          },
          fill: {
            colors: [({value}) => {
              const isAscendentTreshold = sensor.tipo_umbral === 'ascendente'
              const realValue = value * sensor.umbral_alto / 100

              if (isAscendentTreshold) {
                if (realValue < sensor.umbral_medio) {
                  return '#4CAF50'
                } else if (realValue >= sensor.umbral_medio && realValue < sensor.umbral_alto) {
                  return '#FFC107'
                } else {
                  return '#FF5252'
                }
              } else {
                if (realValue > sensor.umbral_medio) {
                  return '#4CAF50'
                } else if (realValue <= sensor.umbral_medio && realValue > sensor.umbral_alto) {
                  return '#FFC107'
                } else {
                  return '#FF5252'
                }
              }
            }]
          },
          plotOptions: {
            radialBar: {
              ...this.opcionesDefaultGraficaRadial.plotOptions.radialBar,
              dataLabels: {
                ...this.opcionesDefaultGraficaRadial.plotOptions.radialBar.dataLabels,
                value: {
                  color: 'black',
                  fontSize: '1.5em',
                  fontWeight: 'bold',
                  offsetY: -10,
                  formatter: function (val) {
                    return `${(val / 100 * sensor.umbral_alto).toFixed(2)} ${sensor.unidad_medida.simbolo}`
                  }
                }
              }
            }
          }
        }

        currentSensor.graficaRadial.series = []
        if (currentSensor.valor_actual) {
          currentSensor.graficaRadial.series = [currentSensor.valor_actual / currentSensor.umbral_alto * 100]
        }

        // Obteniendo data para grafica de area
        currentSensor.graficaArea = {}

        const desde = moment().subtract(1, 'days').format('YYYY-MM-DD hh:mm:ss')
        const hasta = moment().subtract(0, 'days').format('YYYY-MM-DD hh:mm:ss')
        currentSensor.graficaArea.desde = desde
        currentSensor.graficaArea.hasta = hasta
        currentSensor.graficaArea.loading = false
        currentSensor.graficaArea.options = {
          ...this.opcionesDefaultGraficaArea,
          chart: {
            ...this.opcionesDefaultGraficaArea.chart,
            toolbar: {
              export: {
                csv: {
                  dateFormatter(timestamp) {
                    return moment(timestamp).add(5, 'h').format('DD/MM/YYYY HH:mm:ss')
                  },
                  filename: `${sensor.codigo}@${this.codigo}`,
                  headerCategory: 'Fecha',
                  headerValue: 'Registro',
                }
              }
            }
          }
        }

        return {...currentSensor, tipoGrafica: 'lineal'}
      })
    },
    filterSensors(value, query, item) {
      const lowerCaseQuery = query.toLowerCase()
      return item.nombre.toLowerCase().indexOf(lowerCaseQuery) !== -1 ||
        item.nombre_publico.toLowerCase().indexOf(lowerCaseQuery) !== -1 ||
        (item.valor_actual && item.valor_actual.toLowerCase().indexOf(lowerCaseQuery) !== -1) ||
        item.unidad_medida.nombre.toLowerCase().indexOf(lowerCaseQuery) !== -1 ||
        item.unidad_medida.simbolo.toLowerCase().indexOf(lowerCaseQuery) !== -1
    },
    formatDate(date) {
      if (!date) return null

      const [year, month, day] = date.split(' ')[0].split('-')
      return `${day}/${month}/${year}`
    },
    makeMqttConnection() {
      const client = new Paho.Client('mqtt.cobi.pe', 8083, '', '')
      client.onMessageArrived = (message) => {
        const [, , , sensor_codigo] = message.topic.split('/')
        const sensorIndex = this.arraySensoresFormateados.findIndex((sensor) => sensor.codigo === sensor_codigo)
        const currentSensor = this.arraySensoresFormateados[sensorIndex]
        const medida = parseFloat(message.payloadString)
        const porcentaje = medida / currentSensor.umbral_alto * 100

        /* Actualizar series de grafica de area del sensor encontrado y renderizar la gráfica en caso esté
        disponible.
         */
        currentSensor.graficaRadial.series[0] = porcentaje
        if (this.$refs[`graficaRadial${currentSensor.codigo}`]) {
          this.$refs[`graficaRadial${currentSensor.codigo}`].updateSeries(currentSensor.graficaRadial.series)
        }

        /*
        Agregar nuevo registro a gráfica de área y renderizar
         */
        currentSensor.graficaArea.series.forEach((serie) => {
          if (serie.codigo === sensor_codigo) {
            const nuevoRegistro = {
              x: moment().subtract(5, 'hours').toDate(),
              y: medida,
              porcentaje,
            }
            serie.data.unshift(nuevoRegistro)
            if (this.$refs[`graficaArea${currentSensor.codigo}`]) {
              this.$refs[`graficaArea${currentSensor.codigo}`].updateSeries(currentSensor.graficaArea.series)
            }
          }
        })
      }
      client.connect(
        {
          useSSL: true,
          onSuccess: () => {
            console.log('success')
            const topic = `realtime/${this.empresa.codigo}/${this.codigo}/+`
            console.log(topic)
            client.subscribe(topic, {})
          },
          onFailure: (e) => {
            console.log(e)
          }
        },
      )
    },
    async prepareInformation() {
      this.loading = true
      await this.fetchSensores()
      await this.fetchEmpresa()
      this.arraySensoresFormateados = this.formatearSensores(this.sensores)
      this.makeMqttConnection()
      this.loading = false
    },
    fetchRegistrosGrafica(isExpanded, sensor) {
      if (isExpanded) {
        this.actualizarGraficaArea(sensor)
      }

    }
  },
  async mounted() {
    await this.prepareInformation()
  }
}
</script>

<style scoped lang="scss">

.table-title {
  font-weight: 700;
  font-size: 19px;
  line-height: 24px;
  letter-spacing: 0.4px;
  color: #252733;
}

.table-icon {
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.2px;
  color: #4B506D !important;
  margin-bottom: 0;

  &__icon {
    height: 16px;
    width: 16px;
    margin: 0 10px;
  }
}


.sensores-table ::v-deep thead th {
  padding: 0 32px !important;
  border-bottom: 2px solid #DFE0EB !important;
}

.sensores-table ::v-deep thead th {
  font-weight: 700;
  font-size: 14px !important;
  line-height: 18px;
  letter-spacing: 0.2px;
  color: #9FA2B4 !important;
}


.sensores-table ::v-deep tbody tr {
  height: 96px;
}

.sensores-table ::v-deep tbody td {
  padding: 0 32px !important;
  height: 96px;
}

.sensores-table ::v-deep .v-data-footer__select {
  opacity: 0;
}

.sensores-item {
  &__title {
    font-family: 'Mulish';
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.2px;
    color: #252733;
    margin-bottom: 0;
  }

  &__subtitle {
    font-family: 'Mulish';
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.1px;
    color: #C5C7CD;
    margin-bottom: 0;
  }
}

.table-container {
  border: 1px solid #DFE0EB;
  border-radius: 8px;
}

.change-view-button {
  text-transform: none;
  border: 1px solid #DFE0EB !important;
  border-radius: 8px;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.3px;
  color: #252733;
}

.options-card {
  border: 1px solid #DCDDE7;
  background-color: transparent;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1) !important;
  border-radius: 5px;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 20px;
  letter-spacing: 0.2px;
  color: #000000;
  padding: 7px 33px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);

  &__item {
    background-color: white;
    height: 34px;
    width: 143px;
    border: 1px solid #DCDDE7;
    border-radius: 5px;
    cursor: pointer;
  }
}

::v-deep.v-menu__content {
  box-shadow: none;
}

::v-deep.v-menu__content .options-card {
  border: none;
}

.grafica-input {
  display: flex;
  align-items: center;

  &__label {
    min-width: 80px;
    font-weight: 600;
    font-size: 12px;
    line-height: 15px;
    color: #000000;
    margin-bottom: 0;
    text-align: right;
  }
}

.grafica-input__input ::v-deep .v-input__slot fieldset {
  border: 1px solid #DFE0EB;
  border-radius: 8px;
}

.grafica-input__input.grafica-input__append ::v-deep .v-input__slot {
  padding-right: 0 !important;
}

.grafica-input__input ::v-deep .v-input__append-inner {
  padding-top: 8px;
  margin-top: 0 !important;
}

.grafica-input__input.grafica-input__append .tiempos-input {
  font-size: 11px;
  font-weight: 600;
  max-width: 120px;
}

.grafica-input__input ::v-deep .v-chip__content {
  font-size: 10px;
  font-weight: 600;
}

.sensor-searcher {
  max-width: 320px;
}

.base-button {
  letter-spacing: 0;
  text-transform: none;
}


@media (max-width: 599px) {
  .sensores-table ::v-deep tbody td[colspan="5"] {
    height: auto;
  }
}
</style>
